#McapWidget {

  width: 400px;
  margin: 0 auto;
  margin-top: 20px;
/*   margin: 20px; */
  padding:10px;
  padding-bottom: 15px;
  color: white;
  font-size:24px; 
  font-family: 'Exo', sans-serif;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #02EFD6;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
}

#McapWidgetMobile {

  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  padding:5px;
  color: white;
  font-size:12px; 
  font-family: 'Exo', sans-serif;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #02EFD6;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  align-items: center;
}

.logo {
  margin-right: 15px;
  height: 50px;
  width: 50px;
}

.small-text {
  font-size:16px; 
}
.dollar-amt {
  font-size: 40px;
}
.percent-increase {
  color: #14F195;
  font-size: 32px;
}
.dollar-amt-mobile {
  font-size: 30px;
}
.percent-increase-mobile {
  color: #14F195;
  font-size: 20px;
}
.bold {
  font-weight: bold;
}
.samo-price{
  font-size: 18px;
}

.stats-line {
 display:flex;
 align-items: center; 

 justify-content: center;

}



.input:focus,.input:active {
  outline: none !important;
  box-shadow: none;
  background-color: #ef02cf;
  border: none;
}
.btn:focus, .btn:active {
  box-shadow: none !important;
  background: none;
  background-color: #ef02cf;

}
.form-control:focus {
  box-shadow : none;
  background-color: #ef02cf;
  border: none;
}

.toggle-menu {
  background-color: white;
}

.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #ef02cf;
  border:none
}

.toggle-button, .toggle-button:hover, .toggle-button:focus, .toggle-button:active {
  margin:12px;
  border: none;
  background-color: #ef02cf;
  text-decoration: none;
  opacity: .8;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

/* Style for the dropdown container */
.dropdown-link {
  position: relative;
  display: inline-block;
}

/* Style for the dropdown toggle button */
.toggle-button {
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.2s;
}

/* Style for the dropdown menu */
.toggle-menu {
  position: absolute;
  border-radius: 4px;
  right: 0;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-size: 16px;
  text-align: left;
  color: black;
  list-style: none; /* Remove bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

/* Style for each dropdown item */
.toggle-menu li {
  border-radius: 4px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s;
}

.toggle-menu li:hover {
  background-color: #f1f1f1; /* Light grey background on hover */
}


/* Style for the dropdown items */
.dropdown-item {
  border-radius: 4px;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s;
}

.toggle-menu .dropdown-item:hover {
  background-color: #f1f1f1; /* Light grey background on hover */
}
