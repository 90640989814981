#StatsTableMobile {
  border-collapse: separate;
  border-spacing: 1px 1px;
  width: 95%;
  margin: 0 auto;
  overflow-x: scroll;
  overflow-y: scroll;
  color: white;
  font-size:14px; 
  font-weight: 600;
  font-family: 'Exo', sans-serif;
  margin-top:20px;
}

#StatsTable-mobile tr {
min-height: 12px;

}
.data-cell-mobile {
  background-color: aliceblue;
  color:black;
}
.header-column-mobile {
  width: 16%;
  text-align: right;
  font-size:18px; 

}
.data-cell-mobile {
  width: 28%;
  padding: 4px;
  background: rgb(254,252,255);
  background: linear-gradient(0deg, 
  rgba(254,252,255,1) 0%, 
  rgba(234,234,236,1) 50%, 
  rgba(254,254,255,1) 50%, 
  rgba(254,255,255,1) 100%);
}
.data-cell-mobile-highlight {
  color:black;
  width: 28%;
  padding: 4px;
  background: rgb(242, 255, 170);
  background: rgb(228,194,35);
  background: linear-gradient(0deg, rgba(228,194,35,1) 0%, rgba(230,231,70,1) 50%, rgba(253,255,160,1) 50%, rgba(253,255,160,1) 100%);
}

.token-header-common-mobile {
  padding-top: 5px;
  padding-bottom: 5px;
  color:black;
  font-size:14px; 
  font-weight: 900;
}

.doge-header-mobile {
  font-size:14px; 
  background: rgb(181,161,74);
  background: linear-gradient(0deg, rgba(181,161,74,1) 0%, rgba(210,194,116,1) 50%, rgba(225,209,132,1) 50%, rgba(224,205,146,1) 100%);
}
.shiba-header-mobile {
  font-size:14px; 
  background: rgb(223,115,51);
  background: linear-gradient(0deg, rgba(223,115,51,1) 0%, rgba(228,153,95,1) 50%, rgba(239,167,109,1) 50%, rgba(231,169,118,1) 100%);
}

.samo-header-mobile {
  font-size:14px; 
  background: rgb(88,152,226);
  background: linear-gradient(0deg, rgba(88,152,226,1) 0%, rgba(98,210,186,1) 50%, rgba(109,226,184,1) 50%, rgba(130,220,194,1) 100%);
}

.token-logo-mobile {
  height:49px;
  width: 49px;
  margin-top: 6px;
/*   top: -15px;
  position: absolute; */
}

.footnote-mobile {
  color: white;
  float: right;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top:3px;
  margin-right: 5%;
  font-size: 12px;
}


.footer-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 200px;
  width: 100%;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #02EFD6;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(80px);
  color: white;
  text-align: center;
}

.header-row-mobile {
  background-color: rgba(0, 0, 0, 0.7);
}