
@font-face{
  font-family: flick;
  src: url('/src/fonts/flick.ttf');
}
.header {
  overflow: hidden;
  padding-top: 35px;
  padding-bottom: 25px;
  border-style: solid;
  margin: auto;
  border-width: 0px 0px 1px 0px;
  border-color: #02EFD6;
  backdrop-filter: blur(80px);
  font-family: flick;
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}
.rights-reserved {
  overflow: hidden;
  margin-top:50px;
  padding-top: 35px;
  padding-bottom: 25px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #02EFD6;
  backdrop-filter: blur(5px);
  color:white;
  font-family: 'Exo', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}
.rights-reserved-mobile {
  overflow: hidden;
  margin-top:10px;
  padding-top: 35px;
  padding-bottom: 25px;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #02EFD6;
  backdrop-filter: blur(5px);
  color:white;
  font-family: 'Exo', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}

.header-mobile {
  overflow: hidden;
  padding-top: 35px;
  padding-bottom: 35px;
  margin: auto;
  width: 100%;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #02EFD6;
  backdrop-filter: blur(80px);
  backdrop-filter: blur(80px);
  font-family: flick;
  text-align: center;
}

.header a {
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 36px; 
  line-height: 36px;
  border-radius: 4px;
}
.header-mobile a {
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px; 
  line-height: 25px;
  border-radius: 4px;
}
  
.header .logo  {
  font-size: 55px;
  padding-top: 12px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-decoration: none;
  line-height: 25px;
  border-radius: 4px;

}
.header-mobile .logo  {
  font-size: 35px;
  padding-top: 12px;
  color: rgb(255, 255, 255);
  text-align: center;
  text-decoration: none;
  line-height: 25px;
  border-radius: 4px;

}
.logo {
  white-space: nowrap;
}
.header a:hover {
  color: rgb(214, 214, 214);
}

.header-right {
  color: white;
  float: right;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  
  .header-right {
    float: none;
  }
}

.hide {
  display:none;
}

.footer-bar {
  width: 100%;
  margin: 0 auto;
  margin-top:25px;
  padding-bottom:25px;
  background:none;
  border-style:none;
  color:white;
  
}

.footer-bar a {
  color: rgb(255, 255, 255);
  text-align: center;
  text-decoration: none;
  font-size: 18px; 
  line-height: 25px;
  border-radius: 4px;
  padding-right: 8px;
}

.mcap-of {
  font-size: 16px;
}