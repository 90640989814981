.App {
  text-align: center;
  background-image: url('./img/futureback.jpeg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 50px;
  overflow: hidden;

}

.App-link {
  color: #61dafb;
}

button { 
  cursor: pointer;
}